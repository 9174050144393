/* This has the CSS for the shell and common screens */
@font-face {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 300;
  src: url(https://fonts.gstatic.com/s/robotocondensed/v27/ieVo2ZhZI2eCN5jzbjEETS9weq8-_d6T_POl0fRJeyXsosBO5Xk.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/robotocondensed/v27/ieVo2ZhZI2eCN5jzbjEETS9weq8-_d6T_POl0fRJeyWyosBO5Xk.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/robotocondensed/v27/ieVo2ZhZI2eCN5jzbjEETS9weq8-_d6T_POl0fRJeyVVpcBO5Xk.ttf) format('truetype');
}
html {
  height: -webkit-fill-available;
}
body {
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif;
  font-weight: 300;
  background-color: #eee;
  font-size: 10.6px;
  min-height: 100vh;
  /* mobile viewport bug fix */
  min-height: -webkit-fill-available;
}
.react-images__view-image,
.react-images__view-image--isModal img {
  width: auto;
  height: 600px !important;
}
.btn {
  font-size: 1.5rem;
  text-transform: capitalize;
  padding: 7px 18px;
  border-radius: 8px;
}
.btn.btn-lg {
  font-size: 1.6rem;
  padding: 12px 20px;
}
.btn.btn-sm {
  padding: 6px 18px;
  font-size: 1em;
}
.btn.btn-primary {
  background: #3FB5FC;
  border-color: #007AC3;
}
.btn.btn-grey {
  background: #A2ACB4;
  border-color: #707070;
  color: #FFFFFF;
}
.btn.btn-warning {
  background: #FD9A24;
  border-color: #C16723;
}
.btn.btn-default {
  background-color: #fff;
}
.btn.btn-danger {
  border-color: #840000;
}
.btn.btn-success {
  background: #7FC467;
  border-color: #4d8e37;
}
.btn.btn-info {
  background: #06B6B6;
  border-color: #035353;
}
.btn.btn-info:focus {
  background: #035353;
  border-color: #000;
}
.btn.btn-link {
  color: #3FB5FC;
  text-transform: uppercase;
}
.btn.btn-link.left-side {
  padding-left: 0;
}
.btn.btn-link.right-side {
  padding-right: 0;
}
.btn.btn-link:active {
  background: rgba(59, 185, 234, 0.2);
}
.btn:focus {
  outline: none !important;
}
.beacon-form {
  display: block;
}
.beacon-form .form-buttons {
  margin-top: 20px;
}
.beacon-form .datetime-select .form-control-feedback {
  right: 10px;
}
.beacon-form .DraftEditor-root .DraftEditor-editorContainer .public-DraftEditor-content {
  line-height: 20px;
}
.beacon-form .DraftEditor-root {
  min-height: 100px;
}
.beacon-form .has-feedback label ~ .form-control-feedback {
  top: 23px;
}
.beacon-form .react-toggle-label {
  margin-left: 10px;
  vertical-align: top;
  line-height: 30px;
}
.beacon-form.warning .react-select__multi-value {
  background-color: rgba(253, 154, 36, 0.3);
}
.beacon-form.warning .react-toggle--checked .react-toggle-track {
  background-color: rgba(253, 154, 36, 0.3);
}
.beacon-form.warning .react-toggle--checked:hover .react-toggle-track {
  background-color: rgba(193, 103, 35, 0.3);
}
.beacon-form.success .react-select__multi-value {
  background-color: rgba(127, 196, 103, 0.3);
}
.beacon-form.success .react-toggle--checked .react-toggle-track {
  background-color: rgba(127, 196, 103, 0.3);
}
.beacon-form.success .react-toggle--checked:hover .react-toggle-track {
  background-color: rgba(77, 142, 55, 0.3);
}
.beacon-form.info .react-select__multi-value {
  background-color: rgba(12, 90, 139, 0.3);
}
.beacon-form.info .react-toggle--checked .react-toggle-track {
  background-color: rgba(12, 90, 139, 0.3);
}
.beacon-form.info .react-toggle--checked:hover .react-toggle-track {
  background-color: rgba(4, 29, 45, 0.3);
}
.beacon-form.primary .react-select__multi-value {
  background-color: rgba(63, 181, 252, 0.3);
}
.beacon-form.primary .react-toggle--checked .react-toggle-track {
  background-color: rgba(63, 181, 252, 0.3);
}
.beacon-form.primary .react-toggle--checked:hover .react-toggle-track {
  background-color: rgba(3, 132, 210, 0.3);
}
.beacon-form .required-label {
  text-transform: none;
  color: #707070;
}
.beacon-form .rdw-link-modal {
  height: auto;
}
.beacon-form .rdw-editor-wrapper {
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #ccc;
}
.beacon-form .rdw-editor-wrapper .rdw-editor-toolbar {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.beacon-form .rdw-editor-wrapper .rdw-editor-main {
  padding: 0 5px 5px 5px;
  border-radius: 30px;
}
.beacon-form .DraftEditor-root {
  min-height: 300px;
  background-color: white;
  padding-left: 14px;
}
.beacon-form .rdw-editor-main {
  background-color: white;
}
.beacon-form .rdw-editor-toolbar {
  padding: 6px 5px 5px;
  border-radius: 2px;
  border: 1px solid #f1f1f1;
  display: flex;
  justify-content: flex-start;
  background: white;
  flex-wrap: wrap;
  font-size: 15px;
  margin-bottom: 5px;
  -webkit-user-select: none;
  user-select: none;
}
.beacon-form .rdw-option-wrapper {
  display: flex;
}
.beacon-form .DraftEditor-editorContainer,
.beacon-form .DraftEditor-root,
.beacon-form .public-DraftEditor-content {
  line-height: 18px;
  letter-spacing: 2px;
  font-size: 16px;
}
.beacon-form .public-DraftStyleDefault-ol,
.beacon-form .public-DraftStyleDefault-ul {
  margin-right: 4px;
  padding-right: 4px;
}
.beacon-form .public-DraftStyleDefault-orderedListItem {
  list-style-type: decimal;
}
.beacon-form .rdw-link-wrapper,
.beacon-form .rdw-list-wrapper,
.beacon-form .rdw-inline-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  position: relative;
  flex-wrap: wrap;
}
.beacon-form .rdw-option-wrapper {
  border: 1px solid #f1f1f1;
  padding: 5px;
  min-width: 25px;
  height: 20px;
  border-radius: 2px;
  margin: 0 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: white;
  text-transform: capitalize;
}
.form-group {
  margin-bottom: 10px;
}
.form-group .parts-btn {
  max-width: 118px;
}
.form-group .parts-btn_sm {
  max-width: 104px;
}
.form-group .control-label {
  font-weight: normal;
  text-transform: uppercase;
  line-height: 20px;
}
.form-group .form-control {
  margin-top: -5px;
  font-size: 1.2em;
  font-weight: 400;
  font-family: 'Open Sans';
  color: #777;
  box-shadow: none;
  border-radius: 8px;
  height: 36px;
}
.form-group .form-control[name='quantity'] {
  padding-right: 10px;
}
.form-group .input-group-btn {
  font-size: inherit;
}
.form-group .input-group-btn .btn {
  margin-top: -5px;
  padding: 7px 18px 7px 18px;
}
.form-group .react-select__multi-value {
  padding: 2px;
}
.form-group .react-select__control {
  min-height: 36px;
  height: 36px;
  padding: 0 5px 0 5px;
  letter-spacing: normal;
}
.form-group .react-select__control:hover {
  border-color: none;
}
.form-group .react-select__control .react-select__value-container {
  padding: 0;
}
.form-group .is-multi .react-select__control {
  min-height: 40px;
  height: auto;
}
.form-group .react-select__option {
  color: black;
}
.beacon-select .react-select__menu {
  z-index: 5;
}
.slide-modal .modal-footer {
  display: none;
}
.loading-modal .modal-content {
  border-radius: 12px;
}
.loading-modal .modal-body {
  padding: 40px 48px 32px 48px;
}
.loading-modal .modal-title {
  margin-top: 32px;
  margin-bottom: 24px;
}
.two-pane-layout .slide-modal {
  overflow: hidden;
}
.two-pane-layout .slide-modal .modal-body {
  padding: 15px 0 40px 0;
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 54px - 60px - 55px);
}
.two-pane-layout .slide-modal .modal-body .form-buttons {
  position: fixed;
  bottom: 0;
  padding-bottom: 10px;
  padding-top: 10px;
  background-color: #f5f5f5;
  border-top: 1px solid #dcdcdc;
  padding-left: 15px;
  padding-right: 15px;
}
.two-pane-layout .slide-modal .modal-footer {
  display: none;
}
.two-pane-layout .slide-modal .modal-dialog {
  transform: translateY(0%) !important;
  width: 400px;
  margin: 0px auto 0 320px;
}
.two-pane-layout .slide-modal .modal-content {
  background-color: #f5f5f5;
  transition: transform 0.2s;
  border-radius: 0px;
  box-shadow: none;
  min-height: calc(100vh -  54px);
  border: none;
  border-left: 0px #4e5254 solid;
}
.two-pane-layout .slide-modal.from-left .modal-content {
  margin-right: auto;
  transform: translate3d(-100rem, 0, 0);
}
.two-pane-layout .slide-modal.after-open .modal-content {
  transform: translate3d(0rem, 0, 0);
}
.two-pane-layout .slide-modal.second-modal {
  margin: 0px auto 0 320px;
  z-index: 1045;
}
.two-pane-layout .slide-modal.second-modal.after-entered {
  z-index: 1055;
}
.two-pane-layout .slide-modal.second-modal .modal-dialog {
  margin-left: 399px;
}
.two-pane-layout .slide-modal.wide-modal .modal-dialog {
  width: 700px;
}
.two-pane-layout .slide-modal.second-wide-modal {
  margin: 0px auto 0 700px;
}
.sap-modal {
  margin-left: 200px !important;
}
.sap-modal .modal-body {
  overflow-y: hidden !important;
}
.sap-modal .modal-body .ReactTable.beacon-table .rt-thead.-header {
  display: none;
}
.sap-wo-record {
  margin-bottom: 0;
}
.sap-wo-container {
  padding-top: 5px;
  padding-bottom: 5px;
}
.modal-container {
  position: relative;
}
.modal-container .modal,
.modal-container .modal-backdrop {
  position: absolute;
}
.container-fluid {
  height: calc(100vh -  54px);
}
.banner {
  height: 37px;
  width: 100%;
}
.banner .subtitle {
  color: #ffffff;
  font-size: 1.2rem;
}
.banner .title {
  font-size: 1.3rem;
  font-weight: bold;
  color: #ffffff;
  line-height: 39px;
  font-weight: 500;
  padding-left: 30px;
  padding-right: 20px;
  text-transform: uppercase;
  padding-bottom: 2px;
}
.banner .title .breadcrumb {
  background-color: transparent;
  padding: 0px 0px;
}
.banner .title .breadcrumb span,
.banner .title .breadcrumb a {
  color: white;
}
.beacon-toggle.react-toggle--focus .react-toggle-thumb {
  box-shadow: none;
}
.beacon-toggle .react-toggle-thumb {
  border-color: #3FB5FC;
}
.ReactTable.beacon-table {
  font-size: 1.2em;
  font-weight: 400;
  border-top: none;
  max-height: calc(100vh - 179px);
  min-height: 190px;
  overflow: auto;
  background-color: #fff;
}
.ReactTable.beacon-table .rt-tbody .rt-td {
  border-right: none;
}
.ReactTable.beacon-table .rt-tbody .rt-td input[type='checkbox'] {
  margin: 4px 0;
  margin-right: auto;
}
.ReactTable.beacon-table .ReactTable {
  margin-bottom: 30px;
  min-height: 120px;
}
.ReactTable.beacon-table .ReactTable .rt-thead.-header .rt-tr:hover {
  background-color: #fff;
}
.ReactTable.beacon-table .rt-noData {
  transform: withTranslation(-50%, -90%);
  padding: 5px 10px;
}
.ReactTable.beacon-table.dark {
  border-top: 1px solid #013564;
}
.ReactTable.beacon-table.dark .rt-tbody .rt-tr.-odd {
  background-color: rgba(1, 53, 100, 0.05);
}
.ReactTable.beacon-table.dark .rt-tbody .rt-tr.-odd:hover {
  background-color: rgba(1, 53, 100, 0.2);
}
.ReactTable.beacon-table.dark .rt-tbody .rt-tr.-even:hover {
  background-color: rgba(1, 53, 100, 0.2);
}
.ReactTable.beacon-table.green {
  border-top: 1px solid #7FC467;
}
.ReactTable.beacon-table.green .rt-tbody .rt-tr.-odd {
  background-color: rgba(127, 196, 103, 0.05);
}
.ReactTable.beacon-table.green .rt-tbody .rt-tr.-odd:hover {
  background-color: rgba(127, 196, 103, 0.2);
}
.ReactTable.beacon-table.green .rt-tbody .rt-tr.-even:hover {
  background-color: rgba(127, 196, 103, 0.2);
}
.ReactTable.beacon-table.blue {
  border-top: 1px solid #007BC3;
}
.ReactTable.beacon-table.blue .rt-tbody .rt-tr.-odd {
  background-color: rgba(0, 123, 195, 0.05);
}
.ReactTable.beacon-table.blue .rt-tbody .rt-tr.-odd:hover {
  background-color: rgba(0, 123, 195, 0.2);
}
.ReactTable.beacon-table.blue .rt-tbody .rt-tr.-even:hover {
  background-color: rgba(0, 123, 195, 0.2);
}
.ReactTable.beacon-table.blue.no-hover .rt-tbody .rt-tr.-odd,
.ReactTable.beacon-table.blue.no-hover .rt-tbody .rt-tr.-odd:hover {
  cursor: auto;
}
.ReactTable.beacon-table.blue.no-hover .rt-tbody .rt-tr.-even,
.ReactTable.beacon-table.blue.no-hover .rt-tbody .rt-tr.-even:hover {
  cursor: auto;
}
.ReactTable.beacon-table.orange {
  border-top: 1px solid #FD9A24;
}
.ReactTable.beacon-table.orange .rt-tbody .rt-tr.-odd {
  background-color: rgba(253, 154, 36, 0.05);
}
.ReactTable.beacon-table.orange .rt-tbody .rt-tr.-odd:hover {
  background-color: rgba(253, 154, 36, 0.2);
}
.ReactTable.beacon-table.orange .rt-tbody .rt-tr.-even:hover {
  background-color: rgba(253, 154, 36, 0.2);
}
.ReactTable.beacon-table.purple {
  border-top: 1px solid #06B6B6;
}
.ReactTable.beacon-table.purple .rt-tbody .rt-tr.-odd {
  background-color: rgba(6, 182, 182, 0.05);
}
.ReactTable.beacon-table.purple .rt-tbody .rt-tr.-odd:hover {
  background-color: rgba(6, 182, 182, 0.2);
}
.ReactTable.beacon-table.purple .rt-tbody .rt-tr.-even:hover {
  background-color: rgba(6, 182, 182, 0.2);
}
.ReactTable.beacon-table.grey {
  border-top: 1px solid #A2ACB4;
}
.ReactTable.beacon-table.grey .rt-tbody .rt-tr.-odd {
  background-color: rgba(162, 172, 180, 0.1);
}
.ReactTable.beacon-table.grey .rt-tbody .rt-tr.-odd:hover {
  background-color: rgba(162, 172, 180, 0.25);
}
.ReactTable.beacon-table.grey .rt-tbody .rt-tr.-even:hover {
  background-color: rgba(162, 172, 180, 0.25);
}
.ReactTable.beacon-table .rt-td {
  padding: 6px 5px;
  min-height: 38px;
  white-space: normal;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.ReactTable.beacon-table .rt-tr {
  background-color: white;
  cursor: pointer;
}
.ReactTable.beacon-table .rt-tr:first-child {
  padding-left: 20px;
}
.ReactTable.beacon-table .rt-thead .rt-tr {
  text-align: left;
  font-weight: 600;
  text-transform: uppercase;
  background-color: transparent;
  cursor: default;
}
.ReactTable.beacon-table .rt-thead .rt-tr input[type='checkbox'] {
  margin: 0 0 4px;
}
.ReactTable.beacon-table .rt-thead .rt-th {
  border-right: none;
}
.ReactTable.beacon-table .rt-thead .rt-th > div {
  line-height: 1.5;
}
.ReactTable.beacon-table .rt-tbody .rt-tr-group {
  border-bottom: solid 3px rgba(0, 0, 0, 0.05);
  background-color: white;
}
.ReactTable.beacon-table .rt-thead.-header {
  box-shadow: none;
  padding-top: 20px;
}
.ReactTable.beacon-table .-pagination {
  box-shadow: none;
}
@supports (zoom: 2) {
  .ReactTable.beacon-table input[type='radio'],
  .ReactTable.beacon-table input[type='checkbox'] {
    zoom: 1.5;
  }
}
@supports not (zoom: 2) {
  .ReactTable.beacon-table input[type='radio'],
  .ReactTable.beacon-table input[type='checkbox'] {
    transform: scale(1.5);
    margin: 15px;
  }
}
.double-bounce1,
.double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #333;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-animation: sk-bounce 2s infinite ease-in-out;
  animation: sk-bounce 2s infinite ease-in-out;
}
.double-bounce2 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}
@-webkit-keyframes sk-bounce {
  0%,
  100% {
    -webkit-transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
  }
}
@keyframes sk-bounce {
  0%,
  100% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }
  50% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}
.security-functions-list .list-group-item-heading {
  color: #007BC3;
}
.form-control-feedback {
  z-index: 0;
}
.modal-background {
  pointer-events: none;
}
.main-body-content {
  overflow: hidden;
  height: 100%;
}
.options-list.parts-list {
  margin-bottom: 5px;
}
.options-list.parts-list .options-list-item {
  padding: 5px 15px;
  height: 45px;
  text-overflow: ellipsis;
}
.redux-toastr {
  font-size: 1.2rem;
}
.redux-toastr .top-right {
  width: 400px;
}
.camera-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
}
.camera-wrapper .btn-close {
  position: absolute;
  top: 30px;
  right: 30px;
  cursor: pointer;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 50px;
  background-color: hsla(0, 0%, 100%, 0.4);
  border-radius: 50px;
}
.camera-wrapper #display-error {
  display: flex;
  justify-content: center;
  align-items: center;
}
.lbl-error {
  color: #d00000;
}
.signature-control {
  margin-bottom: 1rem;
}
@media screen and (max-width: 1024px) {
  .banner {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    font-family: 'Roboto Condensed';
    height: auto;
    justify-content: flex-start;
    margin: 0;
    padding: 8px 16px;
    width: 100%;
  }
  .banner > span {
    display: block;
    font-size: 20px !important;
    line-height: 1.1 !important;
    padding: 0 !important;
    width: 100%;
  }
  .banner > span:nth-of-type(n + 2) {
    display: inline-block;
    font-size: 12px !important;
    padding: 8px 0 0 !important;
    width: auto;
  }
}
.-sort-desc {
  box-shadow: none !important;
}
.-sort-desc:before {
  content: '▼';
  float: right;
}
.-sort-asc {
  box-shadow: none !important;
}
.-sort-asc:before {
  content: '▲';
  float: right;
}
.damage-code-form {
  display: flex;
  flex-direction: column;
  max-width: 400px;
  margin-top: 15px;
}
.damage-code-form .beacon-select {
  margin-bottom: 10px;
}
.damage-code-form input {
  margin-bottom: 10px;
}
.rdw-fontfamily-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  flex-wrap: wrap;
}
.rdw-fontsize-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  flex-wrap: wrap;
}
.rdw-fontsize-dropdown {
  min-width: 40px;
}
.rdw-fontfamily-dropdown {
  width: 115px;
}
.rdw-dropdown-wrapper {
  height: 30px;
  cursor: pointer;
  border: 1px solid #f1f1f1;
  border-radius: 2px;
  margin: 0 3px;
  text-transform: capitalize;
  background: white;
}
.rdw-dropdown-selectedtext {
  display: flex;
  position: relative;
  height: 100%;
  align-items: center;
  padding: 0 5px;
}
.rdw-dropdown-carettoclose {
  height: 0px;
  width: 0px;
  position: absolute;
  top: 35%;
  right: 10%;
  border-bottom: 6px solid black;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
}
.rdw-dropdown-carettoopen {
  height: 0px;
  width: 0px;
  position: absolute;
  top: 35%;
  right: 10%;
  border-top: 6px solid black;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
}
.rdw-dropdown-optionwrapper {
  z-index: 100;
  position: relative;
  border: 1px solid #f1f1f1;
  width: 98%;
  background: white;
  border-radius: 2px;
  margin: 0;
  padding: 0;
  max-height: 250px;
  overflow-y: scroll;
}
.rdw-dropdownoption-active {
  background: #f5f5f5;
}
.rdw-dropdownoption-default {
  min-height: 25px;
  display: flex;
  align-items: center;
  padding: 0 5px;
}
.margin-right-1 {
  margin-right: 1rem;
}
.loading-progress {
  border-radius: 10px;
  width: 100%;
}
.loading-container {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.loading-speed {
  margin-top: 4px;
}
.loading-action-container {
  margin-top: 32px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
.loading-action-container .retry-btn {
  background-color: #fff;
  height: 36px;
  border-radius: 8px;
  padding: 10px, 24px, 10px, 24px;
  color: #000;
  width: 77px;
  border: 1px solid #CCCCCC;
}
.loading-action-container .exit-logOut-btn {
  border: none;
  background-color: #fff;
  font-size: 14px;
}
.loading-skele {
  width: 100%;
  height: 20px;
  background: linear-gradient(90deg, rgba(240, 240, 240, 0.5) 25%, rgba(224, 224, 224, 0.5) 50%, rgba(240, 240, 240, 0.5) 75%);
  background-size: 200% 100%;
  animation: loading-skele 1.5s infinite;
  border-radius: 2px;
}
@keyframes loading-skele {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}
.header {
  align-items: center;
  background-color: #FFFFFF;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  display: flex;
  height: 54px;
  justify-content: space-between;
  width: 100%;
}
.header .white-rectangle {
  background-color: #fff;
  position: absolute;
  top: -24px;
  right: 0;
  z-index: 11;
  width: 200px;
  height: 73px;
}
.header .profile-text {
  z-index: 100;
}
.header img {
  height: 44px;
  margin: 0 0 5px 30px;
}
.header > span {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
.header .help-button-wrap {
  display: inline-block;
  position: relative;
}
.header .help-button-wrap .help-icon {
  width: 28px;
  height: 28px;
  margin-top: 4px;
  cursor: pointer;
  margin-left: 15px;
}
.header .profile {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  font-size: 1.5rem;
  text-align: center;
}
.header .profile .name {
  font-size: 1.2em;
  color: #007AC3;
  text-transform: capitalize;
}
.header .profile .vertical {
  width: 0;
  outline: 1px solid #013564;
  height: 50px;
  margin-left: 15px;
  margin-right: 10px;
}
.header .profile .shop-cart {
  display: inline-flex;
  margin-left: 12px;
  margin-right: 10px;
}
.header .profile .shop-cart,
.header .profile .shop-cart > button {
  height: 32.5px;
  width: 55px;
}
.header .profile .shop-cart > button {
  margin: 0;
}
.header .profile .animate-badge {
  animation: bounce 2s infinite ease;
}
@keyframes bounce {
  0% {
    transform: scale(1.05, 1) translateY(0);
  }
  10% {
    transform: scale(1.1, 0.9) translateY(0);
  }
  30% {
    transform: scale(0.9, 1.1) translateY(-5px);
  }
  50% {
    transform: scale(1.05, 0.95) translateY(0);
  }
  57% {
    transform: scale(1, 1) translateY(-5px);
  }
  64% {
    transform: scale(1, 1) translateY(0);
  }
  100% {
    transform: scale(1, 1) translateY(0);
  }
}
.header .profile .badge {
  position: absolute;
  top: 14px;
  right: 36px;
  background-color: #FD9A24;
  color: #fff;
  border: solid 1px #fff;
  font-size: 0.8em;
  padding: 2px 5px;
}
.header .header-menu .caret {
  display: none;
}
.header .header-menu .dropdown-toggle {
  z-index: 13;
}
.header .header-menu .dropdown-menu {
  border-radius: 0;
  border-right: none;
  border-top: none;
  box-shadow: none;
  display: block;
  left: -93px;
  opacity: 0;
  top: 52px;
  transform: translateY(-140%);
  transition-duration: 0.2s, 0.2s;
  transition-property: opacity, transform;
  transition-timing-function: ease-in-out;
  z-index: 10;
}
.header .header-menu .dropdown-menu li > a {
  color: #3FB5FC;
}
.header .header-menu .dropdown-menu li.disabled > a {
  color: #AAAAAA;
}
.header .header-menu .dropdown-menu .active > a {
  background-color: transparent;
  color: #AAAAAA;
}
.header .header-menu .dropdown-menu.menu-open {
  opacity: 1;
  transform: translateY(0%);
}
.header .header-menu-button {
  color: #707070;
  font-size: 1.1em;
  margin-top: 0 !important;
  padding: 4px 22px !important;
}
.header .sk-cube-grid {
  display: block;
  height: 20px;
  margin: 0 auto 0 30px;
  position: relative;
  width: 20px;
}
.header .sk-cube-grid .sk-cube {
  width: 33%;
  height: 33%;
  background-color: #FD9A24;
  float: left;
  animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
}
.header .sk-cube-grid .sk-cube1 {
  animation-delay: 0.2s;
}
.header .sk-cube-grid .sk-cube2 {
  animation-delay: 0.3s;
}
.header .sk-cube-grid .sk-cube3 {
  animation-delay: 0.4s;
}
.header .sk-cube-grid .sk-cube4 {
  animation-delay: 0.1s;
}
.header .sk-cube-grid .sk-cube5 {
  animation-delay: 0.2s;
}
.header .sk-cube-grid .sk-cube6 {
  animation-delay: 0.3s;
}
.header .sk-cube-grid .sk-cube7 {
  animation-delay: 0s;
}
.header .sk-cube-grid .sk-cube8 {
  animation-delay: 0.1s;
}
.header .sk-cube-grid .sk-cube9 {
  animation-delay: 0.2s;
}
@keyframes sk-cubeGridScaleDelay {
  0%,
  70%,
  100% {
    transform: scale3D(1, 1, 1);
  }
  35% {
    transform: scale3D(0, 0, 1);
  }
}
.header .offlineStatusCircle {
  position: absolute;
  top: 38px;
  right: 360px;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  border-width: 1px;
  border-style: solid;
}
.header .offlineStatusCircle.red {
  background: #d00000;
  border-color: #840000;
}
.header .offlineStatusCircle.yellow {
  background: #fbde3d;
  border-color: #918127;
}
.header .offlineStatusCircle.green {
  background: #7FC467;
  border-color: #4d8e37;
}
.header .offline-status-override {
  position: absolute;
  top: 10px;
  right: 700px;
}
.header .offline-status-override .btn {
  margin-left: 10px;
}
.header .offline-reset-status {
  align-items: center;
  display: flex;
  font-size: 10px;
  height: 32.5px;
  justify-content: flex-start;
  margin-right: 20px;
}
.header .offline-reset-status span,
.header .offline-reset-status .btn {
  height: 24px;
}
.header .offline-reset-status .pending-text {
  line-height: 2.5;
}
.header .offline-reset-status .btn {
  padding: 0px 12px;
  margin: 1px 0 1px 10px;
  font-size: 1rem;
}
.header .pending-text {
  line-height: 2.9;
}
@media screen and (min-width: 1024px) {
  .menu-welcome {
    display: none;
  }
}
@media screen and (max-width: 1024px) {
  .header .menu-break {
    display: none;
  }
  .header .offline-reset-status {
    z-index: 12;
  }
  .header .welcome-text {
    display: none;
  }
  .header .name {
    display: none;
  }
  .header .dropdown-menu {
    bottom: 0;
    left: 0 !important;
    padding-top: 0px;
  }
  .header .dropdown-menu.menu-open {
    background-color: #007BC3;
    bottom: 0;
    height: calc(100vh - 54px);
    left: 0 !important;
    max-height: calc(100vh - 54px);
  }
  .header .dropdown-menu.menu-open li[role='presentation'] {
    background-color: #007BC3;
    border-bottom: 1px solid #ffffff;
    display: flex;
    height: 70px;
  }
  .header .dropdown-menu.menu-open li[role='presentation'] a {
    color: #ffffff;
    display: flex;
    align-items: center;
    width: 100%;
  }
  .header .dropdown-menu.menu-open li[role='presentation'] a:hover {
    background-color: #013564;
    color: #ffffff;
  }
  .header .menu-welcome {
    display: flex;
    background-color: #f1f1f1;
    padding: 24px;
    justify-content: space-between;
    align-items: flex-end;
    height: 100px;
  }
  .header .menu-welcome-left {
    display: flex;
    flex-direction: column;
    flex-basis: 50%;
  }
  .header .welcome-text-menu {
    color: #013564;
    text-transform: uppercase;
    margin-bottom: 0;
  }
  .header .name-menu {
    color: #007BC3;
    font-size: 24px;
    margin-bottom: 0;
  }
  .header .menu-welcome-right {
    display: flex;
    justify-content: space-evenly;
    flex-basis: 50%;
    align-items: flex-end;
    max-width: 165px;
  }
  .header .shop-cart,
  .header .shop-cart > button {
    width: 42px !important;
  }
  .header .shop-cart > button {
    padding: 4px 0 !important;
  }
  .header .vertical {
    display: none;
  }
  .header .dropdown-toggle svg path {
    fill: #007BC3;
  }
}
.loginlayout {
  background-image: url('images/beaconHome.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  min-height: calc(100vh - 54px);
  /* entire container, keeps perspective */
  /* flip speed goes here */
  /* hide back of pane during swap */
  /* front pane, placed above back */
  /* back, initially hidden pane */
}
.loginlayout .loginForm {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  margin-top: 60px;
  padding: 20px 10px 20px 10px;
  min-height: 320px;
  height: auto;
  width: 450px;
  background-color: rgba(0, 123, 195, 0.7);
  border: 2px solid #FFFFFF;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.3);
}
.loginlayout .loginForm form {
  display: inline-block;
}
.loginlayout .loginForm.login {
  text-align: center;
}
.loginlayout .loginForm.signup {
  min-height: 480px;
}
.loginlayout .loginForm.signup-success {
  background-color: rgba(23, 161, 36, 0.7);
  font-size: 1.3em;
  padding: 20px 30px;
  min-height: 480px;
}
.loginlayout .loginForm .user-form-buttons {
  margin-top: 20px;
}
.loginlayout .loginForm .loginTitle {
  color: #FFFFFF;
  font-size: 2em;
  line-height: 29px;
}
.loginlayout .loginForm .loginBtn {
  color: #007AC3;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.3);
  display: block;
  width: 250px;
  margin: 45px auto 10px auto;
}
.loginlayout .loginForm .loginBtn img {
  float: left;
}
.loginlayout .loginForm .signupBtn {
  color: #FFFFFF;
  margin-top: 20px;
}
.loginlayout .loginForm .ok-button {
  position: absolute;
  right: 10px;
  bottom: 10px;
}
.loginlayout .loginForm .form-group .control-label {
  margin-bottom: 0;
  text-transform: uppercase;
  color: #FFFFFF;
}
.loginlayout .loginForm .form-group {
  margin-bottom: 10px;
  color: #FFFFFF;
}
.loginlayout .loginForm .form-control-feedback-glyphicon {
  top: 30px;
}
.loginlayout .loginForm .has-feedback .form-control {
  padding-right: 30px;
}
.loginlayout .spinner {
  width: 34px;
  top: 0;
  left: 200px;
  height: 34px;
  position: absolute;
}
.loginlayout .flip-container {
  perspective: 1000px;
  margin: 0 auto;
}
.loginlayout .flip-container.flip .flipper {
  transform: rotateY(180deg);
}
.loginlayout .flip-container,
.loginlayout .front,
.loginlayout .back {
  width: 450px;
  height: 480px;
}
.loginlayout .flipper {
  transition: 0.6s;
  transform-style: preserve-3d;
  position: relative;
}
.loginlayout .front,
.loginlayout .back {
  backface-visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
}
.loginlayout .front {
  z-index: 2;
  /* for firefox 31 */
  transform: rotateY(0deg);
}
.loginlayout .back {
  transform: rotateY(180deg);
}
@media screen and (max-width: 1024px) {
  .loginlayout {
    background-position: center;
  }
  .loginlayout .loginForm {
    border: 1px solid #FFFFFF;
    max-width: 85%;
  }
}
.two-pane-layout {
  --filters: 164px;
  --bannerHeight: 38px;
}
.two-pane-layout .side-menu {
  padding-top: 30px;
  height: calc(100vh -  54px);
  background-color: #007AC3;
}
.two-pane-layout .side-menu .svg-inline--fa {
  margin-right: 15px;
}
.two-pane-layout .side-menu .list-group {
  font-size: 20px;
}
.two-pane-layout .side-menu .list-group .list-group-item {
  border: none;
  background-color: #007AC3;
  color: #FFFFFF;
  text-transform: uppercase;
  padding: 5px 15px;
}
.two-pane-layout .side-menu .list-group .list-group-item.active,
.two-pane-layout .side-menu .list-group .list-group-item.active:focus {
  background-color: #004a76;
}
.two-pane-layout .side-menu .list-group .list-group-item:hover {
  background-color: #3BB9EA;
}
.two-pane-layout .side-menu .list-group .list-group-item:first-child {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}
.two-pane-layout .side-menu .list-group .list-group-item:last-child {
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
}
.two-pane-layout .col-fixed {
  z-index: 1060;
  position: relative;
  min-height: 1px;
  float: left;
  width: 100%;
}
.two-pane-layout .col-fluid {
  position: relative;
  min-height: 1px;
  float: left;
  width: 100%;
  overflow: hidden;
  min-height: 80vh;
}
@media (min-width: 768px) and (max-width: 991px) {
  .two-pane-layout .col-fixed {
    width: 320px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .two-pane-layout .col-fixed {
    width: 320px;
  }
}
@media (min-width: 1200px) {
  .two-pane-layout .col-fixed {
    width: 320px;
  }
}
.two-pane-layout .search-table-form {
  margin-top: 10px;
  margin-bottom: 10px;
  padding-left: 22px;
  width: 100%;
}
.two-pane-layout .search-table-form .inventory-action-button {
  text-align: right;
  padding-top: 5px;
  padding-bottom: 10px;
  padding-right: 30px;
}
.two-pane-layout .search-table-form .search-form-button {
  margin-top: 24px;
  padding-left: 0;
}
.two-pane-layout .search-table-form .react-select__menu {
  z-index: 5;
}
@media (min-width: 1200px) {
  .two-pane-layout .search-table-form .inventory-action-button {
    padding-top: 25px;
    padding-right: 0;
  }
}
.table-add-button {
  position: absolute;
  top: 93px;
  right: 20px;
}
.banner-input {
  position: absolute;
  top: 19px;
  right: 65px;
}
.request-for-quote-cart-button {
  position: absolute;
  right: 20px;
  top: 13px;
}
.quote-part-shopping-cart {
  margin-left: 0;
  margin-right: 0;
  padding-left: 20px;
  padding-right: 20px;
  height: calc(100vh - 54px);
  overflow-y: auto;
  overflow-x: hidden;
}
.wo-closing-notes {
  list-style: none;
  margin: 0 0 5px;
  padding: 0;
}
.wo-closing-notes li {
  color: #777;
  font-size: 1.25rem;
  margin: 2.5px 0;
}
@media screen and (max-width: 1024px) {
  .two-pane-layout {
    --filtersMobilePadding: 16px;
  }
  .two-pane-layout .col-fluid {
    height: calc(100vh - 54px);
    overflow-y: auto;
  }
  .two-pane-layout .banner .subtitle {
    line-height: 14px !important;
    min-height: 14px;
  }
  .two-pane-layout .search-form-container,
  .two-pane-layout .search-form-container > div[class^='col'] {
    padding: 0;
    width: 100%;
  }
  .two-pane-layout .search-form-container .mobile-job-header,
  .two-pane-layout .search-form-container .search-actions-container {
    padding: 0 16px 16px;
  }
  .two-pane-layout .search-table-form-mobile {
    margin: 0;
    padding: 16px 16px 0;
    width: 100%;
  }
  .two-pane-layout .search-table-form-mobile .form-group {
    margin-bottom: 16px;
  }
  .two-pane-layout .search-table-form-mobile .form-group .form-control {
    font-size: 14px;
    height: 43px;
  }
  .two-pane-layout .search-table-form-mobile .control-label {
    display: none;
  }
  .two-pane-layout .slide-modal {
    --ios-offset: 2vh;
  }
  .two-pane-layout .slide-modal.second-modal {
    --ios-offset: 3vh;
  }
  .two-pane-layout .slide-modal .modal-content {
    border: none;
    max-height: calc(100vh - 54px - var(--ios-offset));
    min-height: calc(100vh - 54px - var(--ios-offset));
  }
  .two-pane-layout .slide-modal .modal-body,
  .two-pane-layout .slide-modal .modal-body .form-buttons {
    max-width: 500px;
    width: 100vw;
  }
  .two-pane-layout .slide-modal .modal-body .form-buttons {
    left: 0;
    padding-bottom: 30px;
    right: 0;
  }
  .two-pane-layout .slide-modal .modal-body form.search-products-form .form-buttons {
    align-items: flex-start;
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-start;
  }
  .two-pane-layout .slide-modal .modal-body form.search-products-form .form-buttons .btn-link {
    padding-left: 0;
  }
  .two-pane-layout div[data-btn='true'] {
    --filtersBtnHeight: 43px;
    --filtersMobilePadding: 32px;
  }
  .inventory-action-button {
    padding-top: 0 !important;
  }
  .search-actions-container button {
    width: 100% !important;
  }
}
[data-path*='unassigned-sap-workorders'] .manage-inventory {
  --filters: 164px;
}
.disabled-label {
  color: #aeaeae;
}
.blue-bold {
  color: #3FB5FC;
  font-weight: 700;
}
.manage-inventory {
  --filters: 97px;
  --filtersMobileHeight: 103px;
}
.manage-inventory[data-location='devices'] {
  --filters: 77px;
}
.manage-inventory[data-location='repair'] {
  --filters: 134px;
}
.manage-inventory[data-location='sap'] {
  --filters: 126px;
}
.manage-inventory .search-table-form {
  height: var(--filters);
}
.manage-inventory .backdrop {
  background-color: rgba(50, 58, 68, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  bottom: 0;
  right: 0;
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.manage-inventory .ReactTable.beacon-table .rt-thead .rt-th {
  line-height: 1.3;
}
.manage-inventory .ReactTable.beacon-table .rt-thead .rt-th input[type='checkbox'] {
  margin-top: 0px;
}
.manage-inventory .ReactTable.beacon-table.additional-filters {
  max-height: calc(100vh - 179px - 76px - 75px);
}
@media (max-width: 1200px) {
  .manage-inventory .ReactTable.beacon-table.additional-filters {
    max-height: calc(100vh - 54px - 38px - 239px - 75px);
  }
}
.manage-inventory .ReactTable.beacon-table.adding-workorder {
  max-height: calc(100vh - 54px - 38px - var(--filters) - 20px);
}
@media (max-width: 1200px) {
  .manage-inventory .ReactTable.beacon-table.adding-workorder {
    max-height: calc(100vh - 54px - 38px - var(--filters) - 20px);
  }
}
.manage-inventory .ReactTable.beacon-table.adding-workorder-commissioning {
  max-height: calc(100vh - 54px - 38px - var(--filters) - 45px);
}
@media (max-width: 1200px) {
  .manage-inventory .ReactTable.beacon-table.adding-workorder-commissioning {
    max-height: calc(100vh - 54px - 38px - var(--filters) - 20px);
  }
}
.manage-inventory .ReactTable.beacon-table.additional-filters.adding-workorder {
  max-height: calc(100vh - 179px - 76px - 60px - 66px);
}
@media (max-width: 1200px) {
  .manage-inventory .ReactTable.beacon-table.additional-filters.adding-workorder {
    max-height: calc(100vh - 54px - 38px - 239px - 60px - 75px);
  }
}
.manage-inventory .ReactTable.beacon-table .mobile-label {
  color: #a2acb4;
  display: none;
  font-family: 'Roboto Condensed';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  height: 16px;
  line-height: 16px;
  min-width: 61px;
  padding-right: 7px;
  text-transform: uppercase;
  width: 61px;
}
.manage-inventory .ReactTable.beacon-table .attempts-expander .rt-thead .rt-tr {
  margin-top: 50px;
}
.manage-inventory .ReactTable.beacon-table .attempts-expander .rt-tfoot {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  box-shadow: none;
}
.manage-inventory .ReactTable.beacon-table .attempts-expander .rt-tfoot .rt-tr {
  background-color: #f5f5f5;
}
.manage-inventory .ReactTable.beacon-table .attempts-expander .rt-tfoot .rt-td {
  border: none;
  min-height: 50px;
}
.manage-inventory .ReactTable.beacon-table .attempts-expander .rt-tfoot .btn {
  margin-top: 5px;
}
.manage-inventory .request-for-quote-cart-button .badge {
  position: absolute;
  top: 12px;
  right: 3px;
  background-color: #FD9A24;
  color: #fff;
  border: solid 1px #fff;
  font-size: 0.8em;
  padding: 2px 5px;
}
.manage-inventory .quote-item-buttons {
  padding: 0;
}
.manage-inventory .quote-item-buttons .btn-sm {
  padding: 5px 9px;
}
.manage-inventory .quote-item-buttons .badge {
  font-size: 1.1em;
}
.manage-inventory .quote-item-name {
  padding-right: 10px;
}
.manage-inventory .ReactTable.beacon-table .attempts-expander .rt-tr.-odd {
  background-color: rgba(112, 112, 112, 0.05);
}
.manage-inventory .ReactTable.beacon-table .attempts-expander .rt-tr.-odd:hover {
  background-color: rgba(112, 112, 112, 0.2);
}
.manage-inventory .ReactTable.beacon-table .attempts-expander .rt-tr.-even {
  background-color: rgba(238, 238, 238, 0.05);
}
.manage-inventory .ReactTable.beacon-table .attempts-expander .rt-tr.-even:hover {
  background-color: rgba(112, 112, 112, 0.2);
}
.manage-inventory .table-import-button {
  position: absolute;
  top: 93px;
  right: 150px;
}
.manage-inventory .inventory-action-button .dropdown.btn-group {
  float: right;
}
.beacon-list-group {
  background-color: #fff;
}
.beacon-list-group .new-product-item:nth-child(odd) {
  background-color: rgba(127, 196, 103, 0.05);
}
.beacon-list-group .new-product-item h4 {
  font-size: 1.3rem;
  margin-top: 0;
  margin-bottom: 0;
  line-height: 1.9rem;
}
.beacon-list-group .new-product-item div {
  line-height: 18px;
}
.beacon-list-group .new-product-item:hover,
.beacon-list-group .new-product-item.selected {
  background-color: rgba(127, 196, 103, 0.2);
}
.signaturePad {
  border: 1px solid #ccc;
  background-color: #fff;
  border-radius: 8px;
}
@media screen and (max-height: 880px) {
  ul.dropdown-menu[aria-labelledby='inventory-action-button'] {
    height: auto;
    max-height: calc(85vh - 54px - var(--filtersMobileHeight) - 59px);
    overflow-y: auto;
  }
}
@media screen and (max-height: 720px) {
  .header .dropdown-menu li[role='presentation'] {
    height: 46px !important;
  }
}
@media screen and (min-width: 1024px) {
  .manage-inventory .search-form-container {
    display: none;
  }
}
@media screen and (max-width: 1024px) {
  .manage-inventory {
    --bannerHeight: 60px;
  }
  .manage-inventory .add-assets-btn-mobile {
    margin-left: 16px;
  }
  .manage-inventory .mobile-commissioning-container {
    display: flex;
    justify-content: center;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 16px;
  }
  .manage-inventory .mobile-commissioning-container button {
    width: 100%;
  }
  .manage-inventory .inventory-action-button {
    padding: 0 !important;
  }
  .manage-inventory .mng-job-filter {
    padding: 10px 20px;
  }
  .manage-inventory #inventory-action-button,
  .manage-inventory .btn {
    font-size: 14px;
    min-height: 43px;
    line-height: 16px;
    padding: 10px 16px;
  }
  .manage-inventory #inventory-action-button {
    background-color: #3FB5FC;
    border-color: #007AC3;
    color: #fff;
    margin-top: 0;
    max-height: 36px;
  }
  .manage-inventory #inventory-action-button svg {
    margin-left: 8px;
  }
  .manage-inventory .ReactTable.beacon-table input[type='checkbox'] {
    margin: 0 !important;
    zoom: 1;
  }
  .manage-inventory .ReactTable.beacon-table .rt-thead.-header {
    background: #f1f1f1;
    border-bottom: 1px solid #dbdcdd;
    padding: 8px 0;
    min-width: 38px !important;
    width: 100vw;
  }
  .manage-inventory .ReactTable.beacon-table .rt-thead.-header .rt-tr {
    padding: 0 16px;
  }
  .manage-inventory .ReactTable.beacon-table .rt-thead.-header input[type='checkbox'] {
    margin: 0;
  }
  .manage-inventory .ReactTable.beacon-table .rt-thead.-header [role='columnheader'] {
    height: 20px;
    max-width: 100px;
    padding-top: 0;
    padding-bottom: 0;
    width: 100% !important;
  }
  .manage-inventory .ReactTable.beacon-table .rt-thead.-header [role='columnheader']:nth-of-type(1) {
    padding: 0;
  }
  .manage-inventory .ReactTable.beacon-table .rt-thead.-header [role='columnheader']:nth-of-type(2) {
    font-size: 14px;
    font-family: 'Roboto Condensed';
    font-weight: 500;
    color: #a2acb4;
  }
  .manage-inventory .ReactTable.beacon-table .rt-thead.-header [role='columnheader']:nth-of-type(n + 3) {
    display: none;
  }
  .manage-inventory .ReactTable.beacon-table .rt-tbody .rt-tr-group {
    height: auto;
    min-height: 130px;
  }
  .manage-inventory .ReactTable.beacon-table .rt-tbody .rt-tr {
    display: grid !important;
    grid-gap: 10px 16px;
    grid-template-columns: 20px auto;
    grid-template-rows: repeat(3, auto);
    padding: 8px 16px;
  }
  .manage-inventory .ReactTable.beacon-table .rt-tbody .rt-td {
    align-items: center;
    display: flex;
    flex: unset;
    flex-direction: row;
    justify-content: flex-start;
    padding: 0;
    width: 100% !important;
  }
  .manage-inventory .ReactTable.beacon-table .rt-tbody .rt-td .mobile-label {
    display: block;
  }
  .manage-inventory .ReactTable.beacon-table .rt-tbody .rt-td:nth-of-type(1) {
    grid-column: 1;
    grid-row: 1;
    max-width: 20px !important;
    width: 20px !important;
  }
  .manage-inventory .ReactTable.beacon-table .rt-tbody .rt-td:nth-of-type(n + 2) {
    grid-column: 2;
    line-height: 1.2;
  }
  .manage-inventory .ReactTable.beacon-table .rt-tbody .rt-td:nth-of-type(2) {
    grid-row: 1;
  }
  .manage-inventory .ReactTable.beacon-table .rt-tbody .rt-td:nth-of-type(3) {
    grid-row: 2;
  }
  .manage-inventory .ReactTable.beacon-table .rt-tbody .rt-td:nth-of-type(4) {
    grid-row: 3;
  }
}
.manage-form-label {
  color: #007AC3;
  margin-top: -5px;
}
.manage-job {
  --filters: 154px;
}
.manage-job .banner {
  background-color: #FD9A24;
}
.manage-job .approve-buttons {
  padding-right: 12px;
}
.manage-job .approve-buttons .btn {
  padding-left: 8px;
  padding-right: 8px;
}
.backdrop {
  background-color: rgba(50, 58, 68, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  bottom: 0;
  right: 0;
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.job-status {
  line-height: 1.9;
  text-transform: uppercase;
  color: #007AC3;
}
.job-details-content .form-group {
  display: block;
  padding: 0 15px;
  font-size: 1.1em;
}
.facility-address p {
  font-size: 0.9em;
  line-height: 0.9em;
}
.job-edit .map-button-row {
  position: relative;
}
.job-edit .map-button {
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
}
.form-buttons.labor {
  width: auto !important;
}
.mng-job-filter svg,
.add-job-btn svg,
.mng-job-filter img,
.add-job-btn img {
  margin-right: 8px;
}
.search-form-container {
  width: 100vw;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0 5px;
}
.search-form-container .search-form-fields {
  flex: 1;
}
.search-form-container .mobile-job-header {
  margin-top: 2px;
}
@media screen and (min-width: 1024px) {
  .mngJob-table-mobile-header {
    display: none;
  }
  .mng-job-filter {
    display: none;
  }
  .add-job-btn {
    margin-top: 32px;
  }
  .beacon-form .has-feedback label ~ .form-control-feedback {
    top: 28px;
  }
}
.mobile-modal-alt .title {
  width: 100%;
}
.mobile-modal-alt .modal-content {
  border: none;
  box-shadow: none;
  height: calc(100vh - 64px - 43px);
}
.mobile-modal-alt .modal-content .content {
  height: calc(100% - 123px);
}
.mobile-modal-alt .modal-content .form-buttons {
  display: flex;
  padding: 16px 16px 32px 16px;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
  border-top: 1px solid var(--grays-light-gray-tint, #F1F1F1);
}
.mobile-modal-alt .modal-content .form-buttons button {
  width: 50%;
}
.hours-check-mobile {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.hours-check-mobile .content {
  width: 90% !important;
  margin-right: 16px;
  height: auto !important;
}
.hours-check {
  pointer-events: all;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  max-width: 800px;
}
.hours-check .modal-header {
  padding: 0px;
}
.hours-check .title {
  background: var(--Reds-Error-Red, #DA2F2F);
  color: var(--white, #FFF);
  text-align: center;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 32px;
  padding-bottom: 24px;
}
.hours-check .footer {
  padding-top: 32px;
  padding-bottom: 48px;
  color: var(--black, #000);
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.hours-check .content {
  width: 640px;
  overflow: hidden;
  overflow-y: auto;
}
@media screen and (min-width: 1024px) {
  .hours-check .content {
    padding: 40px 48px 40px 64px;
    max-height: 45vh;
  }
  .hours-check .content p {
    text-align: center;
    color: var(--Black, #000);
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    /* 27px */
  }
}
@media screen and (max-width: 1024px) {
  .hours-check .content {
    margin-left: 16px;
    margin-top: 32px;
    max-height: 80vh;
  }
  .hours-check .content p {
    text-align: left;
    color: var(--Black, #000);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    /* 24px */
  }
}
.final-check {
  pointer-events: all;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  max-width: 800px;
}
.final-check .modal-header {
  padding: 0px;
}
.final-check .title {
  background: var(--blues-blue-main, #007BC3);
  color: var(--white, #FFF);
  text-align: center;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 32px;
  padding-bottom: 24px;
}
.final-check .footer {
  background: var(--grays-light-gray, #DBDCDD);
  padding-top: 32px;
  padding-bottom: 48px;
  color: var(--black, #000);
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.final-check .content {
  width: 640px;
  overflow: hidden;
  overflow-y: auto;
}
@media screen and (min-width: 1024px) {
  .final-check .content {
    padding: 40px 48px 40px 64px;
    max-height: 45vh;
  }
}
@media screen and (max-width: 1024px) {
  .final-check .content {
    margin-left: 16px;
    margin-top: 32px;
    max-height: 80vh;
  }
}
.final-check .content .secondary-text {
  color: #777;
}
.final-check .content .inapplicable {
  color: #aeaeae;
}
.final-check .content p {
  margin: 0px;
}
.final-check .content ul {
  margin: 0;
  padding-inline-start: 0px;
}
.final-check .content li {
  margin: 0;
  padding: 10px 0 10px 40px;
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 18px;
  list-style: none;
}
.final-check .content li.facility-contact {
  margin-left: 54px;
  list-style-type: disc;
  padding: 10px 0 10px 12px;
}
.final-check .content li.neutral {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18' fill='none'%3E%3Cline x1='5.25' y1='9' x2='12.75' y2='9' stroke='%23777777' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
}
.final-check .content li.valid {
  background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg id='Status Icons'%3E%3Crect width='18' height='18' rx='9' fill='%2318AB2B'/%3E%3Cpath id='Path 552' d='M4.5 9.36395L7.68198 12.5459L14.0459 6.18197' stroke='white' stroke-width='1.5' stroke-linecap='round'/%3E%3C/g%3E%3C/svg%3E");
}
.final-check .content li.invalid {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18' fill='none'%3E%3Crect width='18' height='18' rx='9' fill='%23DA2F2F'/%3E%3Cpath d='M12.75 5.25L5.25 12.75' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M5.25 5.25L12.75 12.75' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
}
.final-check .content li.inapplicable {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18' fill='none'%3E%3Cline x1='5.25' y1='9' x2='12.75' y2='9' stroke='%23777777' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
}
.manage-job-table .rt-td {
  padding-bottom: 0 !important;
}
@media screen and (max-width: 1024px) {
  .mobile-sap-header {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-evenly;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .ReactTable .rt-tbody,
  .ReactTable.beacon-table .rt-tbody .rt-tr,
  .ReactTable.beacon-table .rt-tbody .rt-tr-group {
    width: 100vw;
  }
  .search-facility .modal-dialog .modal-content .modal-body form .form-buttons {
    display: flex;
    flex-direction: column;
    position: fixed;
    width: 100vw;
  }
  .sap-wo-table .rt-table .rt-thead {
    display: none;
  }
  .sap-wo-table .rt-table .rt-tbody .rt-tr-group .rt-tr .rt-td {
    width: 100% !important;
    max-width: 1024px !important;
    min-height: 25px;
  }
  .manage-job-table {
    float: unset;
    height: 100%;
  }
  .manage-job-table .ReactTable.beacon-table .rt-tbody .rt-tr {
    display: flex;
    flex-direction: column;
    padding: 8px 16px;
  }
  .manage-job-table .ReactTable.beacon-table .rt-tbody .rt-tr .rt-td {
    width: 100% !important;
    max-width: 1024px !important;
    min-height: 25px;
  }
  .manage-job-table .ReactTable.beacon-table .rt-thead.-header {
    display: none;
  }
  .ReactTable .rt-tbody {
    min-width: unset !important;
  }
  .rt-td {
    padding: 0 !important;
  }
  .mobile-job-header {
    display: flex !important;
    justify-content: space-evenly !important;
    flex-direction: row !important;
  }
  .mobile-job-header button {
    margin-top: 0 !important;
  }
  .mobile-job-header a {
    margin-top: 0 !important;
  }
  .sap-button,
  .mng-job-filter,
  .add-job-btn {
    align-items: center;
    display: flex;
    font-size: 14px;
    height: 43px;
    justify-content: center;
    max-width: 152px;
  }
  .mngJob-header-column {
    align-items: center;
    display: flex;
    width: 100%;
  }
  .mngJob-header-column p {
    margin: 0;
  }
  .mngJob-table-mobile-header {
    color: #a2acb4;
    flex-basis: 25%;
    font-family: 'Roboto Condensed';
    font-size: 14px;
    font-weight: 500;
    margin-right: 25px;
    width: 76px;
  }
  .mngJob-table-value {
    flex-basis: 70%;
  }
  .first-row {
    color: #007BC3;
  }
  .ReactTable .-pagination .-pageSizeOptions {
    display: none;
  }
  div[role='dialog'].job-edit .modal-dialog {
    width: 100vw;
  }
  .job-details-content .form-buttons {
    position: fixed;
    bottom: 0;
    padding-bottom: 30px;
  }
}
.manage-location .breadcrumb {
  height: 77px;
  background-color: #eee;
  font-size: 1.2em;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 25px 25px 15px 25px;
}
.manage-location .breadcrumb a {
  color: #3FB5FC;
}
.manage-location .beacon-table .rt-thead .rt-th.-sort-desc {
  box-shadow: none;
}
.measurement-point-list-form {
  margin-left: 0;
  margin-right: 0;
  padding-left: 20px;
  padding-right: 20px;
  overflow-x: hidden;
  height: calc(100vh - 54px);
}
.measurement-point-list-form .banner-wrapper {
  margin-left: -20px;
  margin-right: -20px;
}
.measurement-point-list-form .take-photo-control {
  display: flex;
  align-items: center;
}
.measurement-point-list-form .take-photo-control .btn {
  display: flex;
  align-items: center;
}
.measurement-point-list-form .take-photo-control .btn.btn-primary {
  margin-right: 16px;
}
.measurement-point-list-form .take-photo-control .btn.btn-default {
  color: #333;
  border-color: #ccc;
}
.measurement-point-list-form .take-photo-control .btn img {
  margin-right: 4px;
}
.measurement-point-list-form .ReactTable.beacon-table.photos {
  border: none;
  max-height: calc(100vh - 179px - 100px);
  background-color: transparent;
  /* Hide scrollbar for IE, Edge and Firefox */
}
.measurement-point-list-form .ReactTable.beacon-table.photos .rt-tbody::-webkit-scrollbar {
  display: none;
}
.measurement-point-list-form .ReactTable.beacon-table.photos .rt-tbody {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}
.measurement-point-list-form .ReactTable.beacon-table.photos .rt-tbody .rt-tr-group {
  padding: 10px 0;
  background-color: transparent;
  border: none;
}
.measurement-point-list-form .ReactTable.beacon-table.photos .rt-tbody .rt-tr-group .rt-tr {
  background-color: transparent;
  cursor: default;
}
.measurement-point-list-form .ReactTable.beacon-table.photos .rt-tbody .rt-tr-group .rt-tr .rt-td {
  display: block;
}
.measurement-point-list-form .ReactTable.beacon-table.photos .rt-tr:first-child {
  padding: 0;
}
.measurement-point-list-form .ReactTable.beacon-table.photos .rt-tr:first-child img {
  border: 1px solid grey;
}
.measurement-point-list-form .ReactTable.beacon-table.photos .rt-tr:first-child img:hover {
  border-color: #007ac3;
}
.measurement-point-list-form .datetime-select {
  margin-top: 10px;
}
.measurement-point-list-form .work-order-form .datetime-select {
  margin-top: 0;
}
.measurement-point-list-form .checklist .list-group-item {
  border-radius: 0;
  border: none;
  background-color: transparent;
}
.measurement-point-list-form .col-xs-1,
.measurement-point-list-form .col-sm-1,
.measurement-point-list-form .col-md-1,
.measurement-point-list-form .col-lg-1,
.measurement-point-list-form .col-xs-2,
.measurement-point-list-form .col-sm-2,
.measurement-point-list-form .col-md-2,
.measurement-point-list-form .col-lg-2,
.measurement-point-list-form .col-xs-3,
.measurement-point-list-form .col-sm-3,
.measurement-point-list-form .col-md-3,
.measurement-point-list-form .col-lg-3,
.measurement-point-list-form .col-xs-4,
.measurement-point-list-form .col-sm-4,
.measurement-point-list-form .col-md-4,
.measurement-point-list-form .col-lg-4,
.measurement-point-list-form .col-xs-5,
.measurement-point-list-form .col-sm-5,
.measurement-point-list-form .col-md-5,
.measurement-point-list-form .col-lg-5,
.measurement-point-list-form .col-xs-6,
.measurement-point-list-form .col-sm-6,
.measurement-point-list-form .col-md-6,
.measurement-point-list-form .col-lg-6,
.measurement-point-list-form .col-xs-7,
.measurement-point-list-form .col-sm-7,
.measurement-point-list-form .col-md-7,
.measurement-point-list-form .col-lg-7,
.measurement-point-list-form .col-xs-8,
.measurement-point-list-form .col-sm-8,
.measurement-point-list-form .col-md-8,
.measurement-point-list-form .col-lg-8,
.measurement-point-list-form .col-xs-9,
.measurement-point-list-form .col-sm-9,
.measurement-point-list-form .col-md-9,
.measurement-point-list-form .col-lg-9,
.measurement-point-list-form .col-xs-10,
.measurement-point-list-form .col-sm-10,
.measurement-point-list-form .col-md-10,
.measurement-point-list-form .col-lg-10,
.measurement-point-list-form .col-xs-11,
.measurement-point-list-form .col-sm-11,
.measurement-point-list-form .col-md-11,
.measurement-point-list-form .col-lg-11,
.measurement-point-list-form .col-xs-12,
.measurement-point-list-form .col-sm-12,
.measurement-point-list-form .col-md-12,
.measurement-point-list-form .col-lg-12 {
  padding-left: 10px;
  padding-right: 10px;
}
.measurement-point-list-form .row {
  margin-left: -10px;
  margin-right: -10px;
  margin-bottom: 20px;
}
.measurement-point-list-form .mp-row {
  margin-top: 10px;
}
.measurement-point-list-form .mp-guide {
  line-height: 20px;
  padding-left: 0;
  font-weight: 500;
}
.measurement-point-list-form .mp-header {
  text-transform: uppercase;
  line-height: 1.5;
  border-bottom: 1px solid #333;
}
.measurement-point-list-form .mp-help {
  margin-top: 5px;
}
.measurement-point-list-form .tab-content .tab-pane > :last-child {
  margin-bottom: 40px;
}
.measurement-point-list-form .btn-group .btn:not(.active) {
  background: #AAAAAA;
  border-color: #707070;
}
.measurement-point-list-form .btn-group {
  display: block;
}
.measurement-point-list-form .form-control-feedback {
  right: 10px;
}
.measurement-point-list-form .btn.btn-default {
  color: #007AC3;
  border-color: #007AC3;
}
.lbl-character-count {
  padding-left: 16px;
}
.mp-right-column {
  padding-left: 1px;
}
.mp-button-row {
  padding-left: 0px;
  box-shadow: 0 10px 6px -8px rgba(0, 0, 0, 0.16);
}
.mp-button-row :last-child {
  padding-right: 0px;
}
.mp-button-row :last-child .btn {
  padding-right: 14px;
}
.mp-button-row :first-child .btn {
  padding-left: 16px;
}
.mp-button-row .btn {
  margin-right: 10px;
  font-size: 1.2rem;
}
.measurement-accordian {
  background-color: #fff;
  height: calc(100vh - 54px);
  margin-bottom: 0;
}
.measurement-accordian .panel {
  border: none;
  margin-top: 0;
  border-radius: 0;
}
.measurement-accordian .panel-body {
  height: calc(100vh - 54px - 222px);
  overflow-y: auto;
}
.measurement-accordian .beacon-form > :last-child {
  margin-bottom: 20px;
}
.measurement-accordian .panel-default > .panel-heading {
  background-color: #007BC3;
  color: #fff;
}
.measurement-accordian .panel-heading {
  padding: 0;
  border: none;
  border-radius: 0;
}
.measurement-accordian .panel-title a {
  display: block;
  padding: 7px 15px;
  text-decoration: none;
  font-size: 1.5rem;
}
.measurement-accordian.panel-group .panel + .panel {
  margin-top: 2px;
}
.result-history .history {
  display: flex;
  justify-content: space-between;
}
.result-history .status,
.manage-inventory .status {
  text-transform: uppercase;
  font-weight: 500;
}
.result-history .status.resultStatusFail,
.manage-inventory .status.resultStatusFail {
  color: #d00000;
}
.result-history .status.resultStatusPass,
.manage-inventory .status.resultStatusPass {
  color: #7FC467;
}
.result-history .status.resultStatusNotTested,
.manage-inventory .status.resultStatusNotTested {
  color: #7094ad;
}
.result-history .status.resultStatusCannotComplete,
.manage-inventory .status.resultStatusCannotComplete {
  color: #0c5a8b;
}
.result-history .status.resultStatusRepaired,
.manage-inventory .status.resultStatusRepaired {
  color: #007AC3;
}
.result-history .status.repair,
.manage-inventory .status.repair {
  color: #007AC3;
}
.result-history .status {
  text-align: right;
}
.device-detail-banner-container {
  display: flex;
  border-bottom: 1px solid lightgrey;
  height: 74px;
  background-color: #e0e0e0;
}
.device-detail-banner-container .banner {
  padding-left: 0px;
  padding-top: 8px;
  line-height: 14px;
  display: flex;
  flex-direction: column;
  height: 59px;
}
.device-detail-banner-container .banner .title {
  color: #333333;
  line-height: 1.3;
  padding-left: 0px;
}
.device-detail-banner-container .banner .subtitle {
  padding: 4px 0px;
  color: #333333;
  padding-bottom: 15px;
}
.hide-single-tab .nav.nav-tabs {
  display: none;
}
.work-order-layout .delete-button {
  color: #d00000;
  background-color: #fff;
  margin-right: 20px;
}
.mp-container .mp-row .form-group .vertical-align {
  flex-direction: column;
  align-items: flex-start;
}
.mp-container .mp-row .form-group .vertical-align div input {
  margin-bottom: 10px;
}
.mp-container .mp-guide {
  width: 100%;
  margin-bottom: 10px;
}
#controlled-tab-example-pane-photos {
  padding-top: 20px;
}
@media screen and (max-width: 1024px) {
  .two-pane-layout .col-fluid[data-path^='/devices/'] {
    background-color: #fff;
  }
  .measurement-accordian .panel-body {
    height: calc(100vh - 54px - 40px - 42px - 258px);
  }
  .measurement-point-list-form {
    height: 100%;
  }
  .measurement-point-list-form .row {
    margin-bottom: 0;
  }
  .measurement-point-list-form .mp-guide {
    padding-left: 10px;
  }
  .work-order-form {
    display: flex;
    flex-direction: column;
  }
  .work-order-form div {
    width: 100%;
  }
  .work-order-form .wo-closing-notes-label {
    padding-left: 16px;
  }
}
.mp-tab-back-btn {
  font-size: 3rem;
  padding: 0px 12px 0px 12px !important;
}
body {
  font-size: 12px;
}
#root {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  overflow: hidden;
}
@media screen and (max-width: 415px) {
  @supports (bottom: env(safe-area-inset-bottom)) {
    #root {
      bottom: env(safe-area-inset-bottom);
    }
  }
}
.loginBtn {
  width: 270px;
}
.loginBtn img {
  margin-top: 2px;
}
.two-pane-layout .slide-modal .modal-dialog {
  margin-left: 0;
  width: 500px;
}
.two-pane-layout .slide-modal.second-modal {
  margin-left: 0;
}
.two-pane-layout .slide-modal.second-modal .modal-dialog {
  margin-left: 499px;
}
.ReactTable.beacon-table {
  max-height: calc(100vh - 54px - 38px - 98px);
}
.ReactTable.beacon-table .rt-td {
  padding: 10px 5px;
}
.ReactTable.beacon-table .rt-thead .rt-th.-sort-asc {
  box-shadow: none;
}
@media (max-width: 1200px) {
  .ReactTable.beacon-table {
    max-height: calc(100vh - 54px - var(--bannerHeight) - var(--filters) - 20px);
  }
  .ReactTable.beacon-table .rt-tbody {
    margin-bottom: 46.6px;
  }
  .ReactTable.beacon-table .pagination-bottom {
    background: #fff;
    bottom: 0;
    height: 46.6px;
    left: 0;
    position: fixed;
    right: 0;
  }
  .manage-location .ReactTable.beacon-table {
    max-height: calc(100vh - 54px - 38px - 98px);
  }
  .manage-location .ReactTable.beacon-table .additional-filters {
    max-height: calc(100vh - 54px - 38px - 98px - 48px -20);
  }
  .manage-job-comments .ReactTable.beacon-table {
    max-height: calc(100vh - 54px - 38px - 98px - 48px - 28px);
  }
  .preventative-maintenance .ReactTable.beacon-table {
    max-height: calc(100vh - 54px - 38px - 98px);
  }
}
.btn {
  padding: 10px 24px;
}
.btn.btn-sm {
  padding: 8px 22px;
}
.btn.btn-lg {
  font-size: 1.5em;
  padding: 12px 20px;
}
.beacon-form .form-control {
  height: 42px;
  margin-top: 0;
}
.beacon-form .react-select__option {
  line-height: 2;
}
.header .white-rectangle {
  top: -22px;
  height: 64px;
}
.header .header-menu .dropdown-menu {
  position: fixed;
  right: 0;
  left: auto;
  height: auto;
  font-size: 1em;
}
.header .header-menu-button {
  margin-top: -2px;
}
h4,
.h4 {
  font-size: 1.5em;
}
.vertical-align {
  display: flex;
  align-items: center;
}
.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media screen and (min-width: 1024px) {
  .search-table-form-mobile {
    display: none;
  }
  .search-actions-container.job-comments {
    justify-content: flex-end;
    margin-top: 18px !important;
  }
}
@media screen and (max-width: 1024px) {
  .form-group {
    min-width: 100%;
  }
  .two-pane-layout .slide-modal .modal-header {
    height: 57px;
  }
  .two-pane-layout .slide-modal .modal-body {
    height: calc(100vh - 57px - var(--ios-offset) - 84px);
    overflow: auto;
  }
  .two-pane-layout .slide-modal .modal-body .form-buttons {
    min-height: 84px;
  }
  .two-pane-layout .slide-modal .modal-body .form-buttons button.btn-link {
    padding-left: 0;
  }
  .two-pane-layout .slide-modal .modal-body form.beacon-form > div[class*='col-']:nth-last-of-type(2) {
    padding-bottom: 85px;
  }
  .two-pane-layout .slide-modal .modal-dialog {
    margin-left: 0;
    width: 500px;
  }
  .two-pane-layout .slide-modal.second-modal {
    margin-left: 0;
    position: fixed;
    left: -500px;
  }
  .two-pane-layout .commissioning-data form.beacon-form {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  .two-pane-layout div[data-manage='true'] {
    --check-all: 38px;
    --inputs: calc((43px + 16px) * var(--filter-size));
    --offset: calc(var(--bannerHeight) + var(--filtersMobilePadding) + var(--inputs) + var(--filtersBtnHeight) + var(--check-all));
  }
  @supports (bottom: env(safe-area-inset-bottom)) {
    .two-pane-layout div[data-offset='true'] {
      margin-bottom: 200px;
    }
  }
  .upper-wrap {
    --upper-height: calc(var(--bannerHeight) + var(--filtersMobilePadding) + var(--inputs) + var(--filtersBtnHeight));
    max-height: var(--upper-height);
    min-height: 0;
  }
  .upper-wrap[data-menuopen='false'] {
    overflow: hidden;
  }
  .ReactTable.beacon-table {
    height: auto !important;
    max-height: 100% !important;
  }
  .ReactTable.beacon-table .rt-table {
    overflow-x: hidden;
  }
  [data-empty='true'] {
    overflow: hidden !important;
  }
  [data-empty='true'] .ReactTable.beacon-table {
    overflow: hidden;
    max-height: 90vh !important;
  }
  [data-empty='true'] .ReactTable.beacon-table .rt-tbody {
    overflow: hidden !important;
  }
  .search-table-form {
    display: none;
  }
  .search-actions-container {
    display: flex;
    justify-content: space-between;
    padding: 0;
    width: 83%;
  }
  .search-actions-container button {
    width: 48%;
  }
  .search-form-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0;
    padding: 16px;
  }
  .search-form-container > div {
    width: 100%;
  }
  .search-form-container .btn {
    padding: 10px 16px;
  }
  .search-table-form-mobile form {
    position: relative;
    width: 100%;
  }
  .search-table-form-mobile form .search-table-form-icon {
    position: absolute;
    bottom: 0;
    left: 10px;
    top: 11px;
    z-index: 5;
  }
  .search-table-form-mobile form > div {
    float: unset;
    padding: 0;
    width: 100%;
  }
  .search-table-form-mobile form > div div .control-label {
    display: none;
  }
  .search-table-form-mobile form input.form-control {
    height: 40px;
    padding-left: 34px !important;
  }
  .filter-table-modal-mobile .modal-header,
  .location-table-modal-mobile .modal-header {
    align-items: center;
    border: none;
    display: flex;
    height: 66px;
    justify-content: space-between;
    padding: 20px 16px;
    width: 100%;
  }
  .filter-table-modal-mobile .modal-header h4,
  .location-table-modal-mobile .modal-header h4 {
    margin-right: auto;
  }
  .filter-table-modal-mobile .modal-header button,
  .location-table-modal-mobile .modal-header button {
    background-color: transparent;
    border: none;
    margin: 0 0 0 auto;
    outline: none;
    padding: 0;
  }
  .filter-table-modal-mobile .modal-body,
  .location-table-modal-mobile .modal-body {
    height: auto;
    padding: 24px 16px;
  }
  .filter-table-modal-mobile .modal-body .filter-asset-form-mobile,
  .location-table-modal-mobile .modal-body .filter-asset-form-mobile {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .filter-table-modal-mobile .modal-body .filter-asset-form-mobile .glyphicon,
  .location-table-modal-mobile .modal-body .filter-asset-form-mobile .glyphicon {
    top: 21px;
  }
  .filter-table-modal-mobile .modal-body .filter-asset-form-mobile div,
  .location-table-modal-mobile .modal-body .filter-asset-form-mobile div {
    width: 100%;
  }
  .filter-table-modal-mobile .modal-body .filter-asset-form-mobile div.react-select__indicators,
  .location-table-modal-mobile .modal-body .filter-asset-form-mobile div.react-select__indicators,
  .filter-table-modal-mobile .modal-body .filter-asset-form-mobile div.react-select__indicator,
  .location-table-modal-mobile .modal-body .filter-asset-form-mobile div.react-select__indicator {
    width: auto;
  }
  .filter-table-modal-mobile .modal-body .filter-asset-form-mobile label,
  .location-table-modal-mobile .modal-body .filter-asset-form-mobile label {
    color: #000000;
    font-family: 'Roboto Condensed';
    font-size: 12px;
    line-height: 14px;
    padding-bottom: 4px;
    text-transform: uppercase;
  }
  .filter-table-modal-mobile .modal-body .filter-asset-form-mobile input,
  .location-table-modal-mobile .modal-body .filter-asset-form-mobile input {
    border: 1px solid #cccccc;
    line-height: 14px;
    padding: 10px 13px;
  }
  .filter-table-modal-mobile .modal-body .filter-asset-form-mobile div.react-select__single-value,
  .location-table-modal-mobile .modal-body .filter-asset-form-mobile div.react-select__single-value,
  .filter-table-modal-mobile .modal-body .filter-asset-form-mobile div.react-select__placeholder,
  .location-table-modal-mobile .modal-body .filter-asset-form-mobile div.react-select__placeholder,
  .filter-table-modal-mobile .modal-body .filter-asset-form-mobile input,
  .location-table-modal-mobile .modal-body .filter-asset-form-mobile input {
    font-family: 'Roboto';
    font-size: 12px;
  }
  .filter-table-modal-mobile .modal-body .filter-asset-form-mobile div.react-select__placeholder,
  .location-table-modal-mobile .modal-body .filter-asset-form-mobile div.react-select__placeholder,
  .filter-table-modal-mobile .modal-body .filter-asset-form-mobile input::placeholder,
  .location-table-modal-mobile .modal-body .filter-asset-form-mobile input::placeholder {
    color: #a2acb4;
  }
  .filter-table-modal-mobile .modal-body .filter-job-form-mobile,
  .location-table-modal-mobile .modal-body .filter-job-form-mobile,
  .filter-table-modal-mobile .modal-body .location-form-mobile,
  .location-table-modal-mobile .modal-body .location-form-mobile {
    width: 100%;
  }
  .filter-table-modal-mobile .modal-body .filter-job-form-mobile > div,
  .location-table-modal-mobile .modal-body .filter-job-form-mobile > div,
  .filter-table-modal-mobile .modal-body .location-form-mobile > div,
  .location-table-modal-mobile .modal-body .location-form-mobile > div {
    padding: 0;
    margin-bottom: 8px;
  }
  .filter-table-modal-mobile .modal-body .filter-job-form-mobile > div:nth-of-type(1),
  .location-table-modal-mobile .modal-body .filter-job-form-mobile > div:nth-of-type(1),
  .filter-table-modal-mobile .modal-body .location-form-mobile > div:nth-of-type(1),
  .location-table-modal-mobile .modal-body .location-form-mobile > div:nth-of-type(1) {
    display: none;
  }
  .filter-table-modal-mobile .modal-body .filter-job-form-mobile .glyphicon,
  .location-table-modal-mobile .modal-body .filter-job-form-mobile .glyphicon,
  .filter-table-modal-mobile .modal-body .location-form-mobile .glyphicon,
  .location-table-modal-mobile .modal-body .location-form-mobile .glyphicon {
    top: 21px;
  }
  .filter-table-modal-mobile .modal-body .filter-job-form-mobile div[class^='react-select__value'],
  .location-table-modal-mobile .modal-body .filter-job-form-mobile div[class^='react-select__value'],
  .filter-table-modal-mobile .modal-body .location-form-mobile div[class^='react-select__value'],
  .location-table-modal-mobile .modal-body .location-form-mobile div[class^='react-select__value'] {
    width: 100%;
  }
  .filter-table-modal-mobile .modal-body .filter-job-form-mobile div.react-select__indicators,
  .location-table-modal-mobile .modal-body .filter-job-form-mobile div.react-select__indicators,
  .filter-table-modal-mobile .modal-body .location-form-mobile div.react-select__indicators,
  .location-table-modal-mobile .modal-body .location-form-mobile div.react-select__indicators,
  .filter-table-modal-mobile .modal-body .filter-job-form-mobile div.react-select__indicator,
  .location-table-modal-mobile .modal-body .filter-job-form-mobile div.react-select__indicator,
  .filter-table-modal-mobile .modal-body .location-form-mobile div.react-select__indicator,
  .location-table-modal-mobile .modal-body .location-form-mobile div.react-select__indicator {
    width: auto;
  }
  .filter-table-modal-mobile .modal-body .filter-job-form-mobile label,
  .location-table-modal-mobile .modal-body .filter-job-form-mobile label,
  .filter-table-modal-mobile .modal-body .location-form-mobile label,
  .location-table-modal-mobile .modal-body .location-form-mobile label {
    color: #000000;
    font-family: 'Roboto Condensed';
    font-size: 12px;
    line-height: 14px;
    padding-bottom: 4px;
    text-transform: uppercase;
  }
  .filter-table-modal-mobile .modal-body .filter-job-form-mobile input,
  .location-table-modal-mobile .modal-body .filter-job-form-mobile input,
  .filter-table-modal-mobile .modal-body .location-form-mobile input,
  .location-table-modal-mobile .modal-body .location-form-mobile input {
    border: 1px solid #cccccc;
    line-height: 14px;
    padding: 10px 13px;
  }
  .filter-table-modal-mobile .modal-body .filter-job-form-mobile div.react-select__control,
  .location-table-modal-mobile .modal-body .filter-job-form-mobile div.react-select__control,
  .filter-table-modal-mobile .modal-body .location-form-mobile div.react-select__control,
  .location-table-modal-mobile .modal-body .location-form-mobile div.react-select__control {
    padding-left: 13px;
  }
  .filter-table-modal-mobile .modal-body .filter-job-form-mobile div.react-select__single-value,
  .location-table-modal-mobile .modal-body .filter-job-form-mobile div.react-select__single-value,
  .filter-table-modal-mobile .modal-body .location-form-mobile div.react-select__single-value,
  .location-table-modal-mobile .modal-body .location-form-mobile div.react-select__single-value,
  .filter-table-modal-mobile .modal-body .filter-job-form-mobile div.react-select__placeholder,
  .location-table-modal-mobile .modal-body .filter-job-form-mobile div.react-select__placeholder,
  .filter-table-modal-mobile .modal-body .location-form-mobile div.react-select__placeholder,
  .location-table-modal-mobile .modal-body .location-form-mobile div.react-select__placeholder,
  .filter-table-modal-mobile .modal-body .filter-job-form-mobile input,
  .location-table-modal-mobile .modal-body .filter-job-form-mobile input,
  .filter-table-modal-mobile .modal-body .location-form-mobile input,
  .location-table-modal-mobile .modal-body .location-form-mobile input {
    font-family: 'Roboto';
    font-size: 12px;
  }
  .filter-table-modal-mobile .modal-body .filter-job-form-mobile div.react-select__placeholder,
  .location-table-modal-mobile .modal-body .filter-job-form-mobile div.react-select__placeholder,
  .filter-table-modal-mobile .modal-body .location-form-mobile div.react-select__placeholder,
  .location-table-modal-mobile .modal-body .location-form-mobile div.react-select__placeholder,
  .filter-table-modal-mobile .modal-body .filter-job-form-mobile input::placeholder,
  .location-table-modal-mobile .modal-body .filter-job-form-mobile input::placeholder,
  .filter-table-modal-mobile .modal-body .location-form-mobile input::placeholder,
  .location-table-modal-mobile .modal-body .location-form-mobile input::placeholder {
    color: #a2acb4;
  }
  .filter-table-modal-mobile .modal-footer,
  .location-table-modal-mobile .modal-footer {
    background-color: #f1f1f1;
    height: 68px;
    padding: 16px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .filter-table-modal-mobile .modal-footer button,
  .location-table-modal-mobile .modal-footer button {
    align-items: center;
    border-radius: 8px;
    border: 1px solid transparent;
    color: #fff;
    display: flex;
    flex-direction: row;
    font-family: 'Roboto Condensed';
    font-size: 14px;
    height: 36px;
    justify-content: center;
    line-height: 1.2;
    padding: 10px 24px;
    width: 100%;
  }
  .filter-table-modal-mobile .modal-dialog,
  .location-table-modal-mobile .modal-dialog {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
  }
  .filter-table-modal-mobile .modal-content,
  .location-table-modal-mobile .modal-content {
    height: auto;
    min-height: 100%;
    border-radius: 0;
  }
  .filter-job-form-mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .filter-job-form-mobile div {
    width: 100%;
  }
  #outer-circle {
    bottom: 1vh;
  }
  .datetime-select .rdtPicker {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #f1f1f1;
  }
}
